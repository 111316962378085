import assert from 'assert';

import isPreviewPage from 'dpl/shared/utils/isPreviewPage';
import { SIGNOUT_PATH, ME_PATH } from 'dpl/shared/constants/urls';
import { FAMILY_STATES } from 'dpl/shared/constants/breeder_app';
import { QUERY_KEY_PREFIXES } from 'dpl/shared/fetching/utils/constants';

const DEFAULT_RESOURCE_DEFINITION = {
  defaultValue: {
    meta: {},
    data: []
  }
};

const {
  BREEDER_APP_APPLICATIONS,
  BREEDER_APP_DOG,
  BREEDER_APP_DOGS,
  BREEDER_APP_FAMILIES,
  BREEDER_APP_LITTER,
  BREEDER_APP_LITTERS,
  BREEDER_APP_PAYMENT_SUMMARY,
  BREEDER_APP_PAYMENTS,
  BREEDER_LITTER_OPTIONS,
  CONVERSATION,
  CONVERSATIONS,
  BREEDER_APP_PAYMENT_SETTINGS,
  BREEDER_APP_WAITLIST_MEMBER
} = QUERY_KEY_PREFIXES;

/*
 * adds invalidateAll action, which is a hack to allow invalidation of all
 * states for a specified resource
 *
 * For example: calling invalidateAll on parentsData invalidates resources
 * associated with ?type=(sires|dams)?
 */
function withInvalidateAll(fn, queryKeyPrefix) {
  assert(queryKeyPrefix, 'withInvalidateAll requires `queryKeyPrefix`');

  let lastFetched = Date.now();

  function addInvalidateAll(definition) {
    assert(
      !definition.computeKey,
      'Resource definition must not contain `computeKey`'
    );
    assert(
      !(definition.actions && definition.actions.invalidateAll),
      'Resource definition must not contain `invalidateAll` action'
    );

    return {
      ...definition,
      computeKey: _url => `${_url}${lastFetched}`,
      queryKeyPrefix,
      actions: {
        ...definition.actions,
        invalidateAll: () => {
          lastFetched = Date.now();
          return { url: definition.url };
        }
      }
    };
  }

  if (typeof fn === 'object') {
    return addInvalidateAll(fn);
  }

  return (...args) => addInvalidateAll(fn(...args));
}

export function breederProfilesResourceDefinition(
  breedId,
  itemsPerPage,
  currentPage,
  filters
) {
  return {
    url: `/api/breeds/${breedId}/breeder_profiles/index_v2.json`,
    params: {
      per_page: itemsPerPage,
      page: currentPage,
      ...filters
    },
    defaultValue: {
      meta: {
        available_delivery_methods: []
      },
      data: []
    }
  };
}

export function breederProfileDogResourceDefinition(breederProfileId, dogId) {
  return {
    url: `/api/breeder_profiles/${breederProfileId}/dogs/${dogId}.json`,
    defaultValue: {
      data: {
        health_test_results: [],
        pedigree_documents: []
      }
    },
    params: {
      preview: isPreviewPage()
    }
  };
}

export function breederProfileDogsResourceDefinition(breederProfileId) {
  return {
    url: `/api/breeder_profiles/${breederProfileId}/dogs.json`,
    defaultValue: {
      data: {
        dogs: []
      }
    },
    params: {
      preview: isPreviewPage()
    },
    enabled: Boolean(breederProfileId)
  };
}

export function singleBreederProfileResourceDefinition(breederProfileId) {
  return {
    url: `/api/breeder_profiles/${breederProfileId}`,
    defaultValue: {
      breeds: [],
      user: {}
    },
    params: {
      preview: isPreviewPage()
    }
  };
}

export function breederInterviewResourceDefinition(breederProfileId) {
  return {
    ...DEFAULT_RESOURCE_DEFINITION,
    url: `/api/breeder_profiles/${breederProfileId}/breeder_interview.json`,
    params: {
      preview: isPreviewPage()
    },
    defaultValue: {
      meta: {},
      data: {
        photo: {},
        answers: []
      }
    }
  };
}

export function photographsResourceDefinition(breederProfileId, params = {}) {
  return {
    ...DEFAULT_RESOURCE_DEFINITION,
    url: `/api/breeder_profiles/${breederProfileId}/photographs.json`,
    params: {
      preview: isPreviewPage(),
      ...params
    },
    actions: {
      update: json => ({
        method: 'POST',
        url: `/api/breeder_profiles/${breederProfileId}/photographs.json`,
        body: json,
        updateStrategy: 'replace'
      })
    }
  };
}

export function litterPhotographsResourceDefinition(litterId) {
  return {
    ...DEFAULT_RESOURCE_DEFINITION,
    url: `/api/litters/${litterId}/photographs.json`,
    params: {
      preview: isPreviewPage()
    }
  };
}

export function breederAppEditBreederNewsReadResourceDefinition(
  breederProfileId
) {
  const base = `/api/breeder_app/breeder_profiles/${breederProfileId}/news_items`;
  const updateAction = json => ({
    body: json,
    method: 'PUT',
    url: `${base}.json`,
    updateStrategy: 'success'
  });

  return {
    url: `${base}/edit.json`,
    defaultValue: {
      breeder_profile: {
        last_news_item_seen_at: null
      }
    },
    actions: {
      update: updateAction
    }
  };
}

export function breederAppEditAboutResourceDefinition(breederProfileId) {
  const base = `/api/breeder_app/breeder_profiles/${breederProfileId}`;
  const updateAction = json => ({
    body: json,
    method: 'PUT',
    url: `${base}.json`,
    updateStrategy: 'success'
  });

  return {
    url: `${base}/edit.json`,
    defaultValue: {
      breeder_profile: {
        breeder_breeds_attributes: [],
        breeder_interview_answers_attributes: [],
        breeder_preference_options_attributes: [],
        breeder_profile_certifications_attributes: [],
        breeder_drop_off_points_attributes: [],
        facility_attributes: {
          address_attributes: {}
        },
        local_breeder_profile_clubs_attributes: [],
        national_breeder_profile_clubs_attributes: [],
        profile_photo: {},
        user_attributes: {}
      },
      available_values: {
        breeder_certifications: [],
        breeder_interview_questions: [],
        breeds: [],
        facility_types: [],
        local_clubs: [],
        national_clubs: [],
        states: []
      }
    },
    actions: {
      update: updateAction,
      uploadProfilePhoto: photoAttributes =>
        updateAction({
          breeder_profile: {
            profile_photo_attributes: photoAttributes
          }
        }),
      uploadInterviewPhoto: photoAttributes =>
        updateAction({
          breeder_profile: {
            interview_photo_attributes: photoAttributes
          }
        })
    }
  };
}

export function breederAppEditHealthResourceDefinition(breederProfileId) {
  return {
    url: `/api/breeder_app/breeder_profiles/${breederProfileId}/breed_health/edit.json`,
    actions: {
      update: json => ({
        method: 'PUT',
        url: `/api/breeder_app/breeder_profiles/${breederProfileId}/breed_health.json`,
        body: json,
        refetchAfter: 'success'
      })
    },
    defaultValue: {
      breeder_profile: {
        breeder_breeds_attributes: [],
        breeder_preference_options_attributes: [],
        breeder_training_programs_attributes: []
      },
      available_values: {
        training_program_types: [],
        training_programs: []
      }
    }
  };
}

export function breederAppEditPoliciesResourceDefinition(breederProfileId) {
  const base = `/api/breeder_app/breeder_profiles/${breederProfileId}/policy`;

  return {
    url: `${base}/edit.json`,
    defaultValue: {
      breeder_profile: {
        breeder_delivery_methods_attributes: [],
        breeder_puppy_extras_attributes: []
      },
      available_values: {
        delivery_methods: [],
        extras: []
      }
    },
    actions: {
      update: json => ({
        body: json,
        method: 'PUT',
        url: `${base}.json`,
        refetchAfter: 'success'
      })
    }
  };
}

export function breederAppEditDeliveryResourceDefinition(breederProfileId) {
  const base = `/api/breeder_app/breeder_profiles/${breederProfileId}/delivery`;

  return {
    url: `${base}/edit.json`,
    defaultValue: {
      breeder_profile: {
        breeder_airports_attributes: [],
        breeder_delivery_methods_attributes: []
      },
      available_values: {
        airports: [],
        delivery_methods: []
      }
    },
    actions: {
      update: json => ({
        body: json,
        method: 'PUT',
        url: `${base}.json`,
        refetchAfter: 'success'
      })
    }
  };
}

export const breederAppLittersResourceDefinition = withInvalidateAll(
  (breederProfileId, params = {}) => {
    const url = `/api/breeder_app/breeder_profiles/${breederProfileId}/litters.json`;
    return {
      url,
      params,
      defaultValue: {
        data: [],
        meta: {
          active_count: 0,
          archived_count: 0
        }
      },
      actions: {
        delete: json => ({
          method: 'DELETE',
          url: `/api/breeder_app/breeder_profiles/${breederProfileId}/litters/${json.id}.json`
        })
      }
    };
  },
  BREEDER_APP_LITTERS
);

export const breederAppLitterResourceDefinition = withInvalidateAll(
  (breederProfileId, litterId) => ({
    url: `/api/breeder_app/breeder_profiles/${breederProfileId}/litters/${litterId}/edit.json`,
    defaultValue: {
      litter: {
        litter_parents_attributes: [],
        puppies_attributes: []
      },
      available_values: {
        month_options: []
      }
    },
    actions: {
      update: json => ({
        url: `/api/breeder_app/breeder_profiles/${breederProfileId}/litters/${litterId}.json`,
        method: 'PUT',
        body: json,
        updateStrategy: 'replace'
      }),
      archive: () => ({
        url: `/api/breeder_app/breeder_profiles/${breederProfileId}/litters/${litterId}.json`,
        method: 'PUT',
        body: { litter: { archived_at: new Date() } },
        updateStrategy: 'replace'
      }),
      unarchive: () => ({
        url: `/api/breeder_app/breeder_profiles/${breederProfileId}/litters/${litterId}.json`,
        method: 'PUT',
        body: { litter: { archived_at: null } },
        updateStrategy: 'replace'
      }),
      moveToCurrentLitters: () => ({
        url: `/api/breeder_app/breeder_profiles/${breederProfileId}/litters/${litterId}.json`,
        method: 'PUT',
        body: {
          litter: {
            available: true,
            available_manually_set: true,
            archived_at: null
          }
        },
        updateStrategy: 'replace'
      }),
      moveToPastLitters: () => ({
        url: `/api/breeder_app/breeder_profiles/${breederProfileId}/litters/${litterId}.json`,
        method: 'PUT',
        body: {
          litter: {
            available: false,
            available_manually_set: true,
            archived_at: null
          }
        },
        updateStrategy: 'replace'
      }),
      updatePuppy: puppy => ({
        url: `/api/breeder_app/breeder_profiles/${breederProfileId}/litters/${litterId}.json`,
        method: 'PUT',
        body: { puppies_attributes: puppy },
        updateStrategy: 'replace'
      }),
      markLitterAsReserved: () => ({
        url: `/api/breeder_app/breeder_profiles/${breederProfileId}/litters/${litterId}/mark_as_reserved.json`,
        method: 'PUT',
        refetchAfter: 'success'
      }),
      markLitterAsAvailable: () => ({
        url: `/api/breeder_app/breeder_profiles/${breederProfileId}/litters/${litterId}/mark_as_available.json`,
        method: 'PUT',
        refetchAfter: 'success'
      })
    }
  }),
  BREEDER_APP_LITTER
);

export const breederAppDogsResourceDefinition = withInvalidateAll(
  breederProfileId => ({
    url: `/api/breeder_app/breeder_profiles/${breederProfileId}/dogs.json`,
    defaultValue: {
      data: [],
      meta: {}
    },
    actions: {
      delete: json => ({
        method: 'DELETE',
        url: `/api/breeder_app/breeder_profiles/${breederProfileId}/dogs/${json.id}.json`,
        refetchAfter: 'success'
      }),
      update: json => ({
        url: `/api/breeder_app/breeder_profiles/${breederProfileId}/dogs/${json.id}.json`,
        method: 'PUT',
        body: json,
        refetchAfter: 'success'
      }),
      patch: json => ({
        url: `/api/breeder_app/breeder_profiles/${breederProfileId}/dogs/${json.id}.json`,
        method: 'PATCH',
        body: json,
        refetchAfter: 'success'
      })
    }
  }),
  BREEDER_APP_DOGS
);

export const breederAppDogResourceDefinition = withInvalidateAll(
  (breederProfileId, dogId) => ({
    url: `/api/breeder_app/breeder_profiles/${breederProfileId}/dogs/${dogId}/edit.json`,
    defaultValue: {
      dog: {
        name: '',
        description: '',
        profile_photo_attributes: {
          url: ''
        },
        registration_numbers: [],
        awards: [],
        photographs_attributes: [],
        videos_attributes: [],
        health_test_results_attributes: [],
        color: [],
        color_description: '',
        weight_range_low: '',
        weight_range_high: '',
        size: [],
        coat_type: [],
        generation: []
      },
      available_values: {
        ownership_statuses: [],
        awards: [],
        breeding_statuses: []
      }
    },
    actions: {
      delete: () => ({
        method: 'DELETE',
        url: `/api/breeder_app/breeder_profiles/${breederProfileId}/dogs/${dogId}.json`
      }),
      update: json => ({
        url: `/api/breeder_app/breeder_profiles/${breederProfileId}/dogs/${dogId}.json`,
        method: 'PUT',
        body: json,
        refetchAfter: 'success'
      })
    }
  }),
  BREEDER_APP_DOG
);

export function breederAppDogNotesResourceDefinition(breederProfileId, dogId) {
  return {
    url: `/api/breeder_app/breeder_profiles/${breederProfileId}/dogs/${dogId}/text_logs.json`,
    defaultValue: { data: [] },
    actions: {
      create: json => ({
        url: `/api/breeder_app/breeder_profiles/${breederProfileId}/dogs/${dogId}/text_logs.json`,
        method: 'POST',
        body: json,
        refetchAfter: true
      })
    }
  };
}

export function breederAppBuyerApplicationResourceDefinition(
  breederProfileId,
  applicationId
) {
  const base = `/api/breeder_app/breeder_profiles/${breederProfileId}/buyer_applications/${applicationId}`;
  return {
    url: `${base}/edit.json`,
    defaultValue: {
      buyer_application: {
        buyer_profile: {
          address: {}
        },
        children_ages: [],
        children_count: 0,
        current_pets_types: [],
        gender_preference: '',
        custom_application_answers: []
      }
    },
    actions: {
      update: json => ({
        url: `${base}.json`,
        method: 'PATCH',
        body: json,
        updateStrategy: 'success'
      }),
      markAsSeen: () => ({
        url: `${base}.json`,
        method: 'PATCH',
        body: { buyer_application: { seen: true } },
        updateStrategy: 'success'
      })
    }
  };
}

export function littersResourceDefinition(breederProfileId, params = {}) {
  return {
    ...DEFAULT_RESOURCE_DEFINITION,
    url: `/api/breeder_profiles/${breederProfileId}/litters.json`,
    params: {
      preview: isPreviewPage(),
      ...params
    }
  };
}

export function singleLitterResourceDefinition(breederProfileId, litterId) {
  return {
    url: `/api/breeder_profiles/${breederProfileId}/litters/${litterId}.json`,
    defaultValue: {
      data: {
        extras: [],
        parents: [],
        photographs: [],
        puppies: []
      }
    },
    params: {
      preview: isPreviewPage()
    }
  };
}

export function deliveryMethodsResourceDefinition(breederProfileId) {
  return {
    ...DEFAULT_RESOURCE_DEFINITION,
    url: `/api/breeder_profiles/${breederProfileId}/delivery_methods.json`,
    params: {
      preview: isPreviewPage()
    },
    defaultValue: {
      meta: {},
      data: {
        facility: {},
        pickup: {},
        methods: []
      }
    }
  };
}

export function exploreBreedsResourceDefinition(liveOnly = false) {
  return {
    ...DEFAULT_RESOURCE_DEFINITION,
    url: '/api/explore/breeds.json',
    params: { live_only: liveOnly }
  };
}

export function searchBreedsResourceDefinition(liveOnly = false) {
  return {
    ...DEFAULT_RESOURCE_DEFINITION,
    url: '/api/search/breeds.json',
    params: { live_only: liveOnly }
  };
}

export function breederBreedOptionsResourceDefinition(breederProfileId) {
  return {
    url: `/api/options/breeder_profiles/${breederProfileId}/breeds.json`,
    auto: Boolean(breederProfileId),
    ...DEFAULT_RESOURCE_DEFINITION
  };
}

export const breedOptionsResourceDefinition = {
  ...DEFAULT_RESOURCE_DEFINITION,
  url: '/api/options/breeds.json'
};

export const _photographActions = {
  delete: photoId => ({
    url: `/api/breeder_app/photographs/${photoId}.json`,
    method: 'DELETE',
    refetchAfter: true
  }),
  update: json => ({
    url: `/api/breeder_app/photographs/${json.id}.json`,
    method: 'PUT',
    body: { photograph: json },
    refetchAfter: true
  }),
  add: json => ({
    url: '/api/breeder_app/photographs.json',
    method: 'POST',
    body: json,
    refetchAfter: true
  })
};

export function familyShowResourceDefinition(breederProfileId, familyId) {
  const url = `/api/breeder_app/breeder_profiles/${breederProfileId}/waitlist_members/${familyId}.json`;
  return {
    url,
    auto: Boolean(familyId),
    defaultValue: {
      waitlist_member: {
        buyer_application: {},
        conversation: {},
        shadow_user: {}
      }
    },
    actions: {
      update: json => ({
        method: 'PATCH',
        body: { waitlist_member: json },
        updateStrategy: 'success'
      }),
      archive: () => ({
        method: 'PATCH',
        body: { waitlist_member: { id: familyId, archived: true } },
        refetchAfter: 'success'
      }),
      unarchive: () => ({
        method: 'PATCH',
        body: { waitlist_member: { id: familyId, archived: false } },
        refetchAfter: 'success'
      }),
      moveToWaitlist: () => ({
        method: 'PATCH',
        body: {
          waitlist_member: { id: familyId, status: FAMILY_STATES.APPROVED }
        },
        refetchAfter: 'success'
      }),
      moveToPastFamilies: () => ({
        method: 'PATCH',
        body: { waitlist_member: { id: familyId, status: FAMILY_STATES.PAST } },
        refetchAfter: 'success'
      }),
      delete: () => ({
        url: `/api/breeder_app/breeder_profiles/${breederProfileId}/waitlist_members/${familyId}.json`,
        method: 'DELETE'
      })
    }
  };
}

export const familyResourceDefinition = withInvalidateAll(
  (breederProfileId, familyId) => {
    const updateActionDefinition = (data, params = {}) => ({
      url: `/api/breeder_app/breeder_profiles/${breederProfileId}/waitlist_members/${familyId}.json`,
      method: 'PATCH',
      params,
      body: { waitlist_member: data },
      updateStrategy: 'success'
    });

    return {
      url: `/api/breeder_app/breeder_profiles/${breederProfileId}/waitlist_members${
        familyId ? `/${familyId}/edit.json` : '/new.json'
      }`,
      actions: {
        create: data => ({
          ...updateActionDefinition(data),
          method: 'POST',
          url: `/api/breeder_app/breeder_profiles/${breederProfileId}/waitlist_members.json`,
          updateStrategy: false
        }),
        update: updateActionDefinition,
        assignLitter: litterId =>
          updateActionDefinition({
            dog_id: null,
            litter_id: litterId
          }),
        removeLitter: () =>
          updateActionDefinition({
            dog_id: null,
            litter_id: null
          })
      },
      defaultValue: {
        waitlist_member: {
          shadow_user_attributes: {},
          dog_attributes: {},
          payment_requests_attributes: []
        },
        available_values: {
          litters: [],
          breeds: [],
          states: [],
          breeder_delivery_methods: [],
          breeder_waitlist_preferences: [],
          dams: [],
          sires: []
        }
      }
    };
  },
  BREEDER_APP_WAITLIST_MEMBER
);

export function familyConversationMessagesResourceDefinition(
  breederProfileId,
  familyId
) {
  return {
    url: `/api/breeder_app/breeder_profiles/${breederProfileId}/waitlist_members/${familyId}/conversation_messages.json`,
    actions: {
      create: json => ({
        method: 'POST',
        body: json
      })
    }
  };
}

export function userInviteResourceDefinition(userId, inviteToken) {
  return {
    url: `/api/users/${userId}/invites/edit.json`,
    params: {
      invite_token: inviteToken
    },
    actions: {
      submit: userData => ({
        method: 'PUT',
        url: `/api/users/${userId}/invites.json`,
        body: {
          user: {
            ...userData,
            invite_token: inviteToken
          }
        }
      })
    }
  };
}

export const userSignupResourceDefinition = {
  url: '/api/users/sign_up.json',
  auto: false,
  actions: {
    submit: json => ({
      method: 'POST',
      url: '/api/users.json',
      body: json
    })
  }
};

export function breederProfilePuppiesResourceDefinition(breederProfileId) {
  return {
    ...DEFAULT_RESOURCE_DEFINITION,
    url: `/api/breeder_profiles/${breederProfileId}/puppies.json`,
    params: {
      preview: isPreviewPage()
    }
  };
}

export function breederWaitlistPreferencesResourceDefinition(
  breederProfileId,
  breedId
) {
  return {
    ...DEFAULT_RESOURCE_DEFINITION,
    auto: Boolean(breedId),
    url: `/api/breeder_app/breeder_profiles/${breederProfileId}/breeds/${breedId}/breeder_waitlist_preferences.json`,
    defaultValue: {
      data: [],
      meta: {}
    }
  };
}

export function breederProfileBreedingProcessResourceDefinition(
  breederProfileId
) {
  return {
    url: `/api/breeder_profiles/${breederProfileId}/breeding_process.json`,
    params: {
      preview: isPreviewPage()
    },
    defaultValue: {
      data: {
        training_programs: [],
        training_description: '',
        health_process_description: '',
        breeder_extras: []
      }
    }
  };
}

export const notificationsResourceDefinition = {
  url: '/api/notifications.json',
  defaultValue: {
    data: {
      notifications_count: 0
    },
    meta: {}
  }
};

export function currentUserResourceDefinition() {
  return {
    url: ME_PATH,
    actions: {
      logout: {
        url: SIGNOUT_PATH,
        method: 'DELETE'
      },
      login: json => ({
        method: 'POST',
        url: '/api/users/sign_in.json',
        body: json,
        updateStrategy: 'success'
      })
    },
    defaultValue: {
      data: {
        breeder_profiles_applied_to: null
      },
      meta: {}
    }
  };
}

export function sheltersResourceDefinition(itemsPerPage, currentPage, query) {
  return {
    url: '/api/shelters.json',
    params: {
      per_page: itemsPerPage,
      page: currentPage,
      ...query
    },
    paramsAsFormParams: true,
    defaultValue: {
      meta: {
        total_item_count: 0,
        next_page_url: null
      },
      data: []
    }
  };
}

export function singleShelterResourceDefinition(shelterId) {
  return {
    url: `/api/shelters/${shelterId}.json`,
    defaultValue: {
      data: {
        operating_hours: {},
        dogs: []
      }
    }
  };
}

export function contactHelpResourceDefinition() {
  return {
    auto: false,
    url: '/api/help_requests.json',
    actions: {
      submit: json => ({
        method: 'POST',
        body: json
      })
    }
  };
}

export function newsletterSignupResourceDefinition() {
  return {
    auto: false,
    url: '/api/email_list_registrations.json',
    actions: {
      submit: json => ({
        method: 'POST',
        body: json
      })
    }
  };
}

export function breederProfileBreedsWithAllResourceDefinitions(
  breederProfileId
) {
  return {
    url: `/api/breeder_profiles/${breederProfileId}/preference_options.json`,
    params: {
      preference_type: 'all'
    },
    defaultValue: {
      data: []
    }
  };
}

export function breederAppEditCustomQuestionsResourceDefinition(
  breederProfileId
) {
  const base = `/api/breeder_app/breeder_profiles/${breederProfileId}/custom_application_questions`;
  return {
    url: `${base}/edit.json`,
    defaultValue: {
      breeder_profile: {
        custom_application_questions_attributes: []
      }
    },
    actions: {
      update: json => ({
        body: json,
        method: 'PUT',
        url: `${base}.json`,
        refetchAfter: 'success'
      })
    }
  };
}

export const breederAppPaymentsResourceDefinition = withInvalidateAll(
  (breederProfileId, params = {}) => ({
    url: `/api/breeder_app/breeder_profiles/${breederProfileId}/payment_requests.json`,
    params,
    defaultValue: {
      data: [],
      meta: {}
    }
  }),
  BREEDER_APP_PAYMENTS
);

export const breederAppPaymentSummaryResourceDefinition = withInvalidateAll(
  breederProfileId => ({
    url: `/api/breeder_app/breeder_profiles/${breederProfileId}/payment_summary.json`,
    defaultValue: {
      data: {
        monthly_balance: '0',
        pending_balance: '0',
        total_balance: '0'
      }
    }
  }),
  BREEDER_APP_PAYMENT_SUMMARY
);

export function breederAppEditPaymentRequestResourceDefinition(
  breederProfileId,
  paymentRequestExternalId
) {
  return {
    url: `/api/breeder_app/breeder_profiles/${breederProfileId}/payment_requests/${paymentRequestExternalId}/edit.json`,
    defaultValue: {
      buyer_profile: {}
    },
    actions: {
      update: (paymentRequestId, body) => ({
        url: `/api/breeder_app/breeder_profiles/${breederProfileId}/payment_requests/${paymentRequestId}.json`,
        method: 'PUT',
        body: { payment_request: body },
        refetchAfter: 'success'
      })
    }
  };
}

export function breederAppSinglePaymentResourceDefinition(breederProfileId) {
  const base = `/api/breeder_app/breeder_profiles/${breederProfileId}/payment_requests`;

  return {
    url: `${base}/new.json`,
    defaultValue: {
      payment_request: {
        dog_id: null,
        dog_raised_on_premises: true
      },
      available_values: {
        payment_categories: [],
        litters: [],
        breeds: [],
        delivery_methods: []
      }
    },
    actions: {
      create: json => ({
        method: 'POST',
        body: json,
        url: `${base}.json`,
        refetchAfter: 'success'
      })
    }
  };
}

export function breederAppWaitlistMembersResourceDefinition(
  breederProfileId,
  params = {}
) {
  return {
    ...DEFAULT_RESOURCE_DEFINITION,
    url: `/api/breeder_app/breeder_profiles/${breederProfileId}/waitlist_members.json`,
    params
  };
}

export function paymentRequestBuyerProfileResourceDefinition() {
  const base = '/api/buyer_profiles';

  return {
    url: `${base}/new.json`,
    updateStrategy: 'success',
    defaultValue: {
      buyer_profile: {
        address_attributes: {},
        user_attributes: { claimed: false }
      }
    },
    actions: {
      create: body => ({
        url: `${base}.json`,
        method: 'POST',
        body: {
          buyer_profile: body
        },
        updateStrategy: 'success'
      })
    }
  };
}

export function breederAppPaymentSettingsEditResourceDefinition(
  breederProfileId
) {
  const base = `/api/breeder_app/breeder_profiles/${breederProfileId}/payment_settings`;

  return {
    url: `${base}/edit.json`,
    defaultValue: {
      breeder_profile: {
        billing_profile_attributes: {
          address_attributes: {},
          payout_methods_attributes: []
        }
      },
      available_values: {
        business_types: [],
        payout_locations: []
      }
    },
    actions: {
      update: data => ({
        method: 'PUT',
        url: `${base}.json`,
        body: data,
        updateStrategy: 'success'
      })
    }
  };
}

export const breederAppPaymentSettingsShowResourceDefinition =
  withInvalidateAll(
    breederProfileId => ({
      url: `/api/breeder_app/breeder_profiles/${breederProfileId}/payment_settings.json`,
      defaultValue: {
        data: {}
      }
    }),
    BREEDER_APP_PAYMENT_SETTINGS
  );

export const countriesResourceDefinition = {
  ...DEFAULT_RESOURCE_DEFINITION,
  url: '/api/options/countries.json'
};

export const statesResourceDefinition = {
  ...DEFAULT_RESOURCE_DEFINITION,
  url: '/api/options/states.json'
};

export const responsibleBreedersLetterResourceDefinition = {
  url: '/api/responsible_breeders_letter.json',
  defaultValue: {
    html: ''
  }
};

export const breederAppFamiliesResourceDefinition = withInvalidateAll(
  (breederProfileId, params = { page: 1 }) => {
    const url = `/api/breeder_app/breeder_profiles/${breederProfileId}/waitlist_members.json`;

    const updateBase = familyId => ({
      url: `/api/breeder_app/breeder_profiles/${breederProfileId}/waitlist_members/${familyId}.json`,
      method: 'PATCH'
    });

    return {
      url,
      actions: {
        updatePosition: (familyId, position) => ({
          ...updateBase(familyId),
          body: { waitlist_member: { position } },
          refetchAfter: true
        }),
        archiveFamily: familyId => ({
          ...updateBase(familyId),
          body: { waitlist_member: { archived: true } }
        }),
        updateDog: (familyId, dogId, litterId) => ({
          ...updateBase(familyId),
          body: { waitlist_member: { dog_id: dogId, litter_id: litterId } }
        }),
        unassignDog: familyId => ({
          url: `/api/breeder_app/breeder_profiles/${breederProfileId}/waitlist_members/${familyId}/assignments`,
          method: 'DELETE'
        })
      },
      params,
      defaultValue: {
        data: [],
        meta: {}
      }
    };
  },
  BREEDER_APP_FAMILIES
);

export function breederAppActivitiesResourceDefinition(
  breederProfileId,
  params = {}
) {
  return {
    url: `/api/breeder_app/breeder_profiles/${breederProfileId}/activities.json`,
    auto: Boolean(breederProfileId),
    params,
    ...DEFAULT_RESOURCE_DEFINITION
  };
}

export function breederAppClubsResourceDefinition(breederProfileId) {
  return {
    url: `/api/options/breeder_profiles/${breederProfileId}/breeder_clubs.json`,
    auto: Boolean(breederProfileId),
    defaultValue: {
      data: {
        local: [],
        national: []
      }
    }
  };
}

export function breederAppCertificationsResourceDefinition(breederProfileId) {
  return {
    url: `/api/options/breeder_profiles/${breederProfileId}/breeder_certifications.json`,
    auto: Boolean(breederProfileId),
    ...DEFAULT_RESOURCE_DEFINITION
  };
}

export function breederWaitlistPreferenceOptionsResourceDefinition(
  breederProfileId,
  allBreeds = false
) {
  return {
    url: `/api/options/breeder_profiles/${breederProfileId}/breeder_waitlist_preferences.json`,
    auto: Boolean(breederProfileId),
    params: { all_breeds: allBreeds },
    defaultValue: {
      data: {
        gender: [],
        color: [],
        size: [],
        markings: [],
        coat_type: [],
        generation: []
      }
    }
  };
}

export function breederWaitlistMemberOptionsResourceDefinition(
  breederProfileId
) {
  return {
    url: `/api/options/breeder_profiles/${breederProfileId}/waitlist_members.json`,
    auto: Boolean(breederProfileId)
  };
}

export function dogOptionsResourceDefinition(
  breederProfileId,
  withTbd = false
) {
  return {
    url: `/api/options/breeder_profiles/${breederProfileId}/dogs.json`,
    auto: Boolean(breederProfileId),
    params: { tbd: withTbd },
    defaultValue: {
      data: {
        moms: [],
        dads: []
      }
    }
  };
}

export const paymentRequestCategoryOptionsResourceDefinition = {
  url: '/api/options/payment_request_categories.json',
  defaultValue: {
    data: []
  }
};

export function mediaCategoryOptionsResourceDefinition(type) {
  return {
    url: `/api/options/media_categories/${type}.json`,
    auto: Boolean(type),
    ...DEFAULT_RESOURCE_DEFINITION
  };
}

export function deliveryMethodOptionsResourceDefinition(
  withPickup = false,
  withtMeetHalfway = true
) {
  return {
    url: '/api/options/delivery_methods.json',
    params: {
      with_pickup: withPickup,
      with_meet_halfway: withtMeetHalfway
    },
    ...DEFAULT_RESOURCE_DEFINITION
  };
}

export function airportOptionsResourceDefinition(breederProfileId) {
  return {
    url: `/api/options/breeder_profiles/${breederProfileId}/airports.json`,
    auto: Boolean(breederProfileId),
    ...DEFAULT_RESOURCE_DEFINITION
  };
}

export const healthTestOptionsResourceDefinition = {
  url: '/api/options/health_tests.json',
  ...DEFAULT_RESOURCE_DEFINITION
};

export const breederAppApplicationsResourceDefinition = withInvalidateAll(
  (breederProfileId, params = {}) => {
    const url = `/api/breeder_app/breeder_profiles/${breederProfileId}/buyer_applications.json`;

    return {
      url,
      params,
      defaultValue: {
        data: [],
        meta: {}
      }
    };
  },
  BREEDER_APP_APPLICATIONS
);

export function breederDeliveryMethodOptionsResourceDefinition(
  breederProfileId
) {
  return {
    url: `/api/options/breeder_profiles/${breederProfileId}/breeder_delivery_methods.json`,
    auto: Boolean(breederProfileId),
    ...DEFAULT_RESOURCE_DEFINITION
  };
}

export const breederLitterOptionsResourceDefinition = withInvalidateAll(
  (breederProfileId, params = {}) => ({
    url: `/api/options/breeder_profiles/${breederProfileId}/litters.json`,
    auto: Boolean(breederProfileId),
    params,
    ...DEFAULT_RESOURCE_DEFINITION
  }),
  BREEDER_LITTER_OPTIONS
);

export const conversationResourceDefinition = withInvalidateAll(
  conversationId => ({
    url: `/api/messaging/conversations/${conversationId}.json`,
    auto: Boolean(conversationId),
    actions: {
      markAsRead: currentMemberId => ({
        url: `/api/messaging/conversation_members/${currentMemberId}.json`,
        method: 'PUT',
        body: { conversation_member: { messages_read: true } }
      }),
      sendMessage: ({ messageContent, attachments, currentMemberId }) => ({
        url: '/api/messaging/conversation_messages.json',
        method: 'POST',
        body: {
          conversation_message: {
            conversation_member_id: currentMemberId,
            message_text: messageContent,
            conversation_attachments_attributes: attachments
          }
        }
      })
    },
    defaultValue: {
      conversation: {
        conversation_members: [],
        conversation_messages: []
      }
    },
    getData: data => data.conversation.conversation_messages
  }),
  CONVERSATION
);

export function breedNotificationsResourceDefinition(breedId) {
  return {
    url: `/api/breeds/${breedId}/breed_notifications_lists.json`,
    actions: {
      signup: {
        method: 'POST',
        url: '/api/breed_notifications_lists.json',
        body: { breed_id: breedId }
      },
      unfollow: {
        method: 'DELETE',
        url: `/api/breeds/${breedId}/breed_notifications_lists.json`,
        refetchAfter: 'success'
      }
    }
  };
}

export function breederProfileBreedsWithHealthInfoResourceDefinition(
  breederProfileId
) {
  return {
    url: `/api/breeder_profiles/${breederProfileId}/breeds.json`,
    defaultValue: {
      data: { breeds: [] }
    },
    params: {
      preview: isPreviewPage()
    }
  };
}

export function breedRelationsResourceDefinition(
  breedId,
  citySlug,
  stateSlug,
  slp = false
) {
  return {
    url: `/api/breeds/${breedId}/breed_relations.json`,
    defaultValue: {
      data: []
    },
    params: {
      state_slug: stateSlug,
      city_slug: citySlug,
      slp
    }
  };
}

export function conversationMemberResourceDefinition(conversationMemberId) {
  return {
    url: `/api/messaging/conversation_members/${conversationMemberId}.json`,
    defaultValue: {
      conversation_member: {}
    },
    actions: {
      archive: {
        method: 'PUT',
        body: {
          conversation_member: {
            archive: true
          }
        }
      },
      activate: {
        method: 'PUT',
        body: {
          conversation_member: {
            activate: true
          }
        }
      }
    }
  };
}

export const conversationsResourceDefinition = withInvalidateAll(
  ({ params = { page: 1, archived: false }, ...rest } = {}) => ({
    ...rest,
    url: '/api/messaging/conversations.json',
    params,
    defaultValue: {
      conversations: []
    },
    getData: data => data.conversations
  }),
  CONVERSATIONS
);

export function buyerNotificationResourceDefinition(buyerId) {
  return {
    url: `/api/buyer_profiles/${buyerId}/buyer_notification_settings/edit.json`,
    defaultValue: {
      meta: {},
      buyer_notification_setting: {
        buyer_application_follow_up: false,
        community_updates: false
      }
    },
    actions: {
      update: json => ({
        method: 'PUT',
        url: `/api/buyer_profiles/${buyerId}/buyer_notification_settings.json`,
        body: json,
        updateStrategy: 'success'
      })
    }
  };
}

export function breedListMembersNotificationResourceDefinition(buyerId) {
  return {
    url: `/api/buyer_profiles/${buyerId}/breed_list_members`,
    defaultValue: {
      meta: {},
      data: []
    },
    actions: {
      update: json => ({
        method: 'PUT',
        body: json,
        refetchAfter: 'success'
      })
    }
  };
}

export function breederWaitlistLitterOptionsResourceDefinition(
  breederProfileId
) {
  return {
    url: `/api/options/breeder_profiles/${breederProfileId}/waitlist_litters.json`,
    defaultValue: {
      meta: {},
      data: {
        litters: [],
        unassigned_waitlist_members_count: 0
      }
    }
  };
}

export function buyerApplicationForSuccessModalResourceDefinition(
  buyerProfileId,
  buyerApplicationId
) {
  return {
    url: `/api/buyer_profiles/${buyerProfileId}/buyer_applications/${buyerApplicationId}.json`,
    defaultValue: {
      breeder_profile: {},
      litter: {}
    }
  };
}

export function breederProfileAvailabilityResourceDefinition(breederProfileId) {
  return {
    url: `/api/breeder_profiles/${breederProfileId}/availability.json`,
    defaultValue: {
      data: {
        can_override_availability: false,
        breeds: []
      }
    },
    enabled: Boolean(breederProfileId)
  };
}

export function buyerApplicationResourceDefinition(breederProfileId) {
  return {
    url: `/api/breeder_profiles/${breederProfileId}/buyer_applications.json`,
    defaultValue: {
      buyer_application: {}
    }
  };
}

export function breederWaitlistPreferencesByBreederResourceDefinition(
  breederProfileId
) {
  return {
    url: `/api/breeder_profiles/${breederProfileId}/breeder_waitlist_preferences.json`
  };
}

export function adoptionProcessResourceDefinition(breederProfileId) {
  return {
    url: `/api/breeder_profiles/${breederProfileId}/adoption_process.json`,
    defaultValue: {
      data: {}
    },
    params: {
      preview: isPreviewPage()
    }
  };
}

export function moreAboutBreederResourceDefinition(breederProfileId) {
  return {
    url: `/api/breeder_profiles/${breederProfileId}/more_about_breeder.json`,
    defaultValue: { meta: {}, data: [] }
  };
}

export function aboutBreedsResourceDefinition(breederProfileId) {
  return {
    url: `/api/breeder_profiles/${breederProfileId}/about_breeds.json`,
    defaultValue: { data: { breeds: [] } }
  };
}

export function exploreBreedsByState(breederProfileId) {
  return {
    url: `/api/breeder_profiles/${breederProfileId}/explore_breeds_by_state.json`,
    defaultValue: { data: { breeds: [] } }
  };
}

export function breedLocationInfoResourceDefinition({
  breedId,
  citySlug,
  stateSlug
}) {
  return {
    url: `/api/breeds/${breedId}/breed_location_info.json`,
    params: { city_slug: citySlug, state_slug: stateSlug },
    defaultValue: { meta: {}, data: { breed_info: null } },
    createFetchDataConfig: { ignoreRescueOn: [404] }
  };
}

export function nearbyLocationsResourceDefinition({
  breederProfileId,
  postalCode = null
}) {
  return {
    url: `/api/breeder_app/breeder_profiles/${breederProfileId}/nearby_locations.json`,
    params: { postal_code: postalCode },
    defaultValue: { data: [] },
    enabled: Boolean(breederProfileId),
    createFetchDataConfig: { ignoreRescueOn: [404] }
  };
}
